<template>
  <div>
    <div v-if="slipDetails" class="reciept-page-content">
      <div class="reciept">
        <div class="reciept-bg-top"></div>

        <div class="thanks-message-container">
          <template class="modal-body text-center">
            <div class="thanks-img-wrapper">
              <!-- <img src="@/assets/images/shared/truck.png" alt /> -->
            </div>
            <h2>Thank You!</h2>
            <p>Your orders are on the way.</p>
            <div v-html="statusData"></div>
          </template>
        </div>

        <div class="payment-reciept-wrapper">
          <div class="payment-details-meta">
            <div class="order-no">
              <span>#{{orderId}}</span>
            </div>
            <div class="date">
              <span class="text-color-2">{{slipDetails.createdAt}}</span>
            </div>
          </div>
          <div class="products" @scroll="onProductsScroll">
            <transition name="fade" v-if="totalQty > 5">
              <div class="scroll-message text-center text-white" v-if="!isScrolled">
                SCROLL
                <i class="scroll-down-icon fal fa-angle-down"></i>
              </div>
            </transition>
            <div v-for="(product, index) in slipDetails.details.products" :key="index">
              <div class="product d-flex justify-content-between align-items-center">
                <div class="product-image-name d-flex justify-content-between align-items-center">
                  <div class="product-image">
                    <img :src="product.image" alt />
                  </div>
                  <div class="product-name">
                    <span class="quantity">{{product.qty}}</span>x
                    <span class="product-name">{{product.name}}</span>
                  </div>
                </div>
                <div class="product-amount">
                  <span>B {{product.qty * product.price}}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="product-price-details-wrapper clearfix">
            <div class="product-price-details float-right">
              <div class="product-price-detail subtotal d-flex">
                <div class="product-price-detail-title">Subtotal:</div>
                <div class="product-price">B {{totalPrice}}</div>
              </div>
              <div class="product-price-detail delievery d-flex">
                <div class="subtotal product-price-detail-title">Delievery:</div>
                <div class="product-price">B 100</div>
              </div>
              <div class="product-price-detail total d-flex">
                <div class="subtotal product-price-detail-title">Total:</div>
                <div class="product-price --total">B {{totalPrice + 100}}</div>
              </div>
            </div>
          </div>

          <div class="horizontal-dotted-line"></div>

          <div class="shipping-contact-details" v-if="slipDetails.details.customer">
            <div class="shiping-info-title">
              <span>Shipping Info</span>
            </div>
            <div class="shipping-info-wrapper d-flex justify-content-between align-items-center">
              <div class="shipping-info">
                <span>{{slipDetails.details.customer.address}}</span>
              </div>
              <div class="contact-phone">
                <span class="contact-icon">
                  <i class="fal fa-phone"></i>
                </span>
                {{slipDetails.details.customer.mobileNo}}
              </div>
            </div>
          </div>

          <div class="button-group-reciept d-flex justify-content-center">
            <!-- <router-link
              :to="{name: 'Receipt', params:{orderId:orderId, redirect:'false'}}"
              class="back-to-slip-button btn btn-outline-primary mr-4"
            >Go Back</router-link> -->
            <button class="back-to-shop-button btn btn-primary ml-4">Share Link</button>
          </div>
        </div>
        <div class="reciept-bg-down"></div>
      </div>

      <!-- <Modal id="upload-payment-slip" ref="uploadSlipModal">
        <template slot="header">
          <h5 class="modal-title">
            <i class="fal fa-file-import mr-2"></i>Upload Slip
          </h5>
        </template>
        <template slot="body">
          <p class="lead text-center">Drop the file here to upload it to the system</p>
          <p class="text-center" v-if="sample_file_link">
            Please Make sure the format matches this
            <a :href="sample_file_link">sample file</a>
          </p>
          <vue2Dropzone
            ref="importDropZone"
            id="upload-slip-dropzone"
            :options="importDropzoneOptions"
          ></vue2Dropzone>
        </template>
      </Modal> -->
    </div>
    <div v-else>
      <Spinner size="md" variation="primary" />
    </div>
  </div>
</template>

<script>
// import Button from "@/components/core/Button";
// import Modal from "@/components/core/Modal";
import Spinner from "@/components/core/Spinner";
// import vue2Dropzone from "vue2-dropzone";
import { getOrderStatus } from "@/helpers/core";

export default {
  name: "PaymentDetails",
  components: { 
    // Button, 
    // Modal, 
    // vue2Dropzone, 
    Spinner },
  methods: {
    onProductsScroll() {
      return (this.isScrolled = true);
    },
    selectPaymentMethod(paymentMethod) {
      return (this.selectedPaymentMethod = paymentMethod);
    },
    fetchSlipDetails() {
      this.$store.dispatch("order/fetchOrderDetails", {
        id: this.orderId,
        callback: (status, data) => {
          if (status) {
            this.slipDetails = data;
          }
          // else this.$router.push({ name: "404" });
        }
      });
    },

    paymentMethodClass(paymentMethod) {
      return paymentMethod.name == this.selectedPaymentMethod.name
        ? "--active"
        : "";
    }
  },
  computed: {
    totalPrice() {
      if (this.slipDetails && this.slipDetails.details.products) {
        let total = 0;
        Object.values(this.slipDetails.details.products).forEach(
          product => (total += product.qty * product.price)
        );
        return total;
      }
      return 0;
    },
    statusData() {
      let statusData = getOrderStatus(this.slipDetails.details.status || 0);
      return `<span class="badge badge-pill badge-${statusData.variation} text-uppercase">${statusData.title}</span>`;
    },
    totalQty() {
      if (this.slipDetails.details.products)
        return Object.values(this.slipDetails.details.products).length;
      return 0;
    }
  },
  created() {
    this.selectedPaymentMethod = this.paymentMethods[0];
    this.fetchSlipDetails();

    // get order code from url
    // check order code in DB
    // if not exist - redirect to 404 not found page

    // if exist
    // check if payment has been made
    // if payment has been made, redirect to ThankYou Page
  },
  data() {
    return {
      isScrolled: false,
      orderId: "order-6245c5e0-3548-11ec-8ca8-11e8fab39c681635134554430",
      slipDetails: null,
      paymentMethods: [
        {
          name: "Bank",
          image_url:
            "https://media.licdn.com/dms/image/C510BAQElNZUFumis3Q/company-logo_200_200/0?e=2159024400&v=beta&t=6ZRf4U9q8QdPQglYSMDSCt63hv3RagkGk0_feYkyZGk",
          detail: "9672278122 Kolvacharawong Derm, Siam Commercial Bank"
        },
        {
          name: "PayPal",
          image_url:
            "https://lh3.googleusercontent.com/Y2_nyEd0zJftXnlhQrWoweEvAy4RzbpDah_65JGQDKo9zCcBxHVpajYgXWFZcXdKS_o",
          detail: "payments@proship.com"
        }
      ],
      sample_file_link: null,
      importDropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" }
      },
      selectedPaymentMethod: {}
    };
  }
};
</script>

<style lang="scss" scoped>
.reciept-page-content {
  overflow: hidden;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.payment-method {
  padding: 22px;
  margin-right: 20px;
  border-radius: 12px;
  cursor: pointer;

  &.--active {
    background-color: map-get($text-colors, 6);
  }

  img {
    width: 60px;
    height: 60px;
  }
}

.thanks-img-wrapper {
  width: 40%;
  margin: 0 auto 40px auto;
}

.payment-status-tag {
  width: 70%;
  margin: 0 auto;
  margin-top: 16px;
  padding: 2px 0;
  border-radius: 20px;
  &.--pending {
    color: $brand-yellow-dark;

    background-color: $brand-yellow-dark-25;
  }
  &.--done {
    color: $brand-green-mid;
    background-color: $brand-green-mid-25;
  }
}

.thanks-message-container {
  @media only screen and (min-width: 1584px) {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0;
    border-radius: 20px;
    width: 460px;
  }
  position: relative;
  background-color: white;
}

.payment-reciept-wrapper {
  width: 100%;
  height: 100%;
  padding: 38px;
  background-color: white;

  @include for-laptops-only {
    padding: 12px;
  }

  @include for-desktops-and-up {
    min-height: 715px;
  }


}

.scroll-message {
  background-color: map-get($text-colors, 3);
  font-size: 12px;
  padding: 5px 12px;
  border-radius: 20px;
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 50px;

  .scroll-down-icon {
    margin-left: 6px;
  }
}
.contact-icon {
  margin-right: 8px;
}

.button-group-reciept {
  margin-top: 30px;
  @include for-laptops-only {
    margin-top: 15px;
  }
}

.shiping-info-title {
  color: map-get($text-colors, 5);
}
.shipping-contact-details {
  font-size: 14px;
  font-weight: $font-weight-medium;
  color: map-get($text-colors, 4);
}
.shipping-info {
  width: 180px;
}

.shipping-info-wrapper {
  margin-top: 4px;
}
.horizontal-dotted-line {
  border: 1px dashed #cdcdcd;
  width: 100%;
  margin: 25px 0;
  @include for-laptops-only {
    margin: 15px 0;
  }
}

.payment-details-meta {
  font-size: 14px;
  color: map-get($text-colors, 5);
  margin-bottom: 40px;
  @include for-laptops-only {
    margin-bottom: 20px;
  }
}

.product-image {
  margin-right: 12px;
  width: 52px;
  height: 52px;
  img {
    border-radius: 5px;
    width: 52px;
    height: 52px;
  }
}

.products {
  max-height: 304px;
  overflow: scroll;
  overflow-x: hidden;
  font-size: 14px;
  position: relative;

  @include for-laptops-only {
    max-height: 150px;
  }
}

.product {
  color: map-get($text-colors, 4);
  margin-bottom: 24px;
  @include for-laptops-only {
    margin-bottom: 8px;
  }
}

.product-amount {
  margin-right: 14px;
}

.product-price {
  width: 100%;
  text-align: right;
  &.--total {
    color: map-get($text-colors, 3);
    font-size: 22px;
    font-weight: $font-weight-medium;
  }
}

html {
  overflow: auto;
}
body {
  position: absolute;
  top: 20px;
  left: 20px;
  bottom: 20px;
  right: 20px;
  padding: 30px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.product-price-details {
  color: map-get($text-colors, 5);
  font-size: 14px;
  .product-price-detail-title {
    margin-right: 91px;
    width: 72px;
  }
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 3px;
  border-radius: 3x;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: map-get($text-colors, 6);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: map-get($text-colors, 6);
}
</style>